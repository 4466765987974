import { MobileContainer } from '../../layout/MobileContainer';
import React, { useEffect } from 'react';
import { ChildSummary } from '../../children/ChildSummary';
import { Box, Button, IconButton, Link } from '@chakra-ui/react';
import { useChild } from '../../lib/ChildContext';
import { ChildTypeSummary } from '../../children/ChildTypeSummary';
import { useApi } from '../../lib/useApi';
import { ChildWithType } from '../../../model/child';
import { PlanSummary } from '../../order/PlanSummary';
import NextLink from 'next/link';
import { LoadingPage } from '../LoadingPage';
import { ShippingPlan } from '../../../model/shippingPlan';
import { Icon } from '../../icons';
import BannerImage from '../../../components/line/banner.png';
import { useAuthContext } from '../../lib/AuthContext';
import { LineModal } from '../../line/LineModal';
import { ProductExample } from './ProductExample';
import { Banner } from '../../common/Banner';
import { signOut } from '../../../lib/auth';
import { useRouter } from 'next/router';
import { CrownText } from '../../common/CrownText';
import BulkBanner from './bulk_banner.png';

type User = {
  id: number;
  lineId: number | null;
};

export const MyPageTop = () => {
  const { isAuthenticated } = useAuthContext();
  const { data: userData, error } = useApi<{ user: User }>(
    isAuthenticated ? 'v1/users/self' : null
  );
  const { currentChild } = useChild();
  const { data: childData } = useApi<{ child: ChildWithType }>(
    currentChild && `v1/self/children/${currentChild.id}`
  );
  const { data: planData } = useApi<{ shippingPlans: ShippingPlan[] }>(
    currentChild && `v1/self/shipping_plans?child_id=${currentChild.id}`
  );
  // const { data: tokenData } = useApi<{ token: string }>(
  //   isAuthenticated ? 'v1/self/crew_tokens' : null
  // );
  const isLoading = !currentChild || !childData || !planData || typeof userData === 'undefined';
  const router = useRouter();

  useEffect(() => {
    if (error) {
      signOut();
      router.replace('/sign_in');
    }
  }, [error]);

  if (isLoading) return <LoadingPage />;

  return (
    <MobileContainer>
      <Box m={4}>
        <ChildSummary
          mode={'detail'}
          child={currentChild}
          action={
            <NextLink href={'/children/select'}>
              <IconButton aria-label={'change'}>
                <Icon icon={'change'} w={5} />
              </IconButton>
            </NextLink>
          }
        />
      </Box>
      {childData && <ChildTypeSummary child={childData.child} m={4} />}
      {planData.shippingPlans && <ProductExample shippingPlans={planData.shippingPlans} />}
      {planData.shippingPlans && <PlanSummary shippingPlans={planData.shippingPlans} />}
      <Box m={4}>
        <Link href={'/setting/children?page=skip'}>
          <Button
            variant="solid"
            w="full"
            colorScheme="orange"
            color="white"
            bg="#FF6F61"
            rounded="4"
            h={10}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              style={{ marginRight: 8 }}
            >
              <path
                d="M14.8125 4.18064L11.2354 0.196263C10.957 -0.0967058 10.4795 0.111302 10.4795 0.524388V2.30564C4.72266 2.30564 0.0585938 6.96384 0.0585938 12.7119C0.0585938 13.4795 0.146484 14.2236 0.304687 14.9414C1.32715 10.2656 5.49023 6.76462 10.4766 6.76462V8.51365C10.4766 8.92673 10.9541 9.13474 11.2324 8.84177L14.8096 4.83689C14.9854 4.65525 14.9854 4.36228 14.8125 4.18064Z"
                fill="white"
              />
            </svg>
            <CrownText white fontSize={13} bold lineHeight={1}>
              お届けをスキップ
            </CrownText>
          </Button>
        </Link>
      </Box>
      <Box bg="white" py="1px">
        {planData?.shippingPlans.length > 1 && planData?.shippingPlans.length < 8 && (
          <Banner href={'https://lp.crown-box.jp/plan'} img={BulkBanner.src} />
        )}
        <Banner
          href={'https://crown-box.jp/shop/customer/invite_coupons'}
          img={'https://d3ho5d329vxdum.cloudfront.net/invite_2000.png'}
        />
        <Banner
          href={'https://crown-box.jp'}
          img={
            'https://d2w53g1q050m78.cloudfront.net/crownboxjp/uploads/themes/main_20220731165308/ec_force/assets/images/brother-order.png'
          }
        />

        {!userData?.user.lineId && (
          <>
            <LineModal />
            <Banner href={'/line/login'} img={BannerImage.src} />
          </>
        )}
      </Box>
    </MobileContainer>
  );
};
